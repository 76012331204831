/*
 * Copyright (C) 2022 SailPoint Technologies, Inc. All rights reserved.
 */

/**
 * Enum for all feature flags used within sp-renderer.
 */
export enum FeatureFlags {
	// This is an example flag used in the example app provided along side atlasjs that
	// demonstrants some features.
	CAM_TEMP_20230525_INCLUDE_FEDRAMP_URL = 'CAM_TEMP_20230525_INCLUDE_FEDRAMP_URL',
	CAM_CLOUD_UI_COMMON_CAM17507_INCLUDE_DEMO_URL = 'CAM_CLOUD_UI_COMMON_CAM17507_INCLUDE_DEMO_URL',
	PLTUI_RENDERER_TEST_CONTROLLER = 'PLTUI_RENDERER_TEST_CONTROLLER',
	PENDO_STAGING = 'PENDO_STAGING',
	PENDO_SUPPRESS = 'PENDO_SUPPRESS',
	PENDO_FOR_ALL = 'PENDO_FOR_ALL',
	PENDO_FOR_USER = 'PENDO_FOR_USER',
	PENDO_FOR_ADMIN = 'PENDO_FOR_ADMIN',
	PLT_UI_RENDERER_REQUESTS_INTERCEPTOR = 'PLT_UI_RENDERER_REQUESTS_INTERCEPTOR',
	PENDO_DESIGNER = 'PENDO_DESIGNER',
	PLT_UI_DAS_LOGOUT_FROM_CC = 'PLT_UI_DAS_LOGOUT_FROM_CC',
	IAI_UI_QLIK_DASHBOARD = 'IAI_UI_QLIK_DASHBOARD',
	PLT_UI_QUICKSIGHT_DASHBOARD = 'PLT_UI_QUICKSIGHT_DASHBOARD',
	PLT_UI_USE_FEATURE_FLAG_CACHE = 'PLT_UI_USE_FEATURE_FLAG_CACHE',
	PLT_UI_MYSAILPOINT_NAVIGATION = 'PLT_UI_MYSAILPOINT_NAVIGATION',
	PLT_UI_HOME_PAGES = 'PLT_UI_HOME_PAGES',
	PLTUI_HOME_PAGE_ROUTE_TO_MYSAILPOINT_ROUTE = 'PLTUI_HOME_PAGE_ROUTE_TO_MYSAILPOINT_ROUTE',
	PLTUI_UI_MYSAILPOINT_ALL_USERS = 'PLTUI_UI_MYSAILPOINT_ALL_USERS',
	PLTUI_7782_FIX_TOKEN_REFRESH = 'PLTUI_7782_FIX_TOKEN_REFRESH',
	GOV_UI_REDIRECT_TO_APP_MANAGER = 'GOV_UI_REDIRECT_TO_APP_MANAGER',
	UI_INFRA_PENDO_SESSION_REPLAY = 'UI_INFRA_PENDO_SESSION_REPLAY',
	UI_INFRA_EMBED_YOUTUBE_VIDEOS_CSP = 'UI_INFRA_EMBED_YOUTUBE_VIDEOS_CSP',
	UI_INFRA_EMBED_VIDYARD_VIDEOS_CSP = 'UI_INFRA_EMBED_VIDYARD_VIDEOS_CSP',
	SHARED_AUTH_ALLOWED_AS_AUTH_TENANT = 'SHARED_AUTH_ALLOWED_AS_AUTH_TENANT',
	PLT_UI_NAVBAR_CLEANUP_URLS = 'PLT_UI_NAVBAR_CLEANUP_URLS',
	PLTUI_SAML_REDIRECT = 'PLTUI_SAML_REDIRECT',
	UIDATA960_QLIK_EMBED_M2M = 'UIDATA960_QLIK_EMBED_M2M',
	PLTUI9155_PLTUI_ENABLE_REAUTH_PROCESS = 'PLTUI9155_PLTUI_ENABLE_REAUTH_PROCESS',

	/**
	 * This feature flag enables the GET /ui/session endpoint which allows users easy access to the oauth access token.
	 */
	PLTUI_DISABLE_GET_UI_SESSION_ENDPOINT = 'PLTUI_DISABLE_GET_UI_SESSION_ENDPOINT',

	/**
	 * Stop clearing the session anytime there is an error.
	 */
	PLTUI_STOP_CLEARING_THE_SESSION_ON_ERROR = 'PLTUI_STOP_CLEARING_THE_SESSION_ON_ERROR',

	/**
	 * Additional logging around the geodetection
	 */
	PLTUI_GEOLOCATION_LOGGING = 'PLTUI_GEOLOCATION_LOGGING',

	/** Only proxy a single x-forward-for address to login-info */
	PLTUI_X_FORWARD_FOR_SINGLE_ADDRESS = 'PLTUI_X_FORWARD_FOR_SINGLE_ADDRESS',

	/**
	 * Reject login requests from stale urls, such as when the user bookmarked the login page
	 */
	PLTUI_REJECT_OLD_LOGIN_URLS = 'PLTUI_REJECT_OLD_LOGIN_URLS',

	/**
	 * Respond with correct http status code for error pages
	 */
	PLT_UI_TMP_ERROR_PAGE_STATUS_CODE = 'PLT_UI_TMP_ERROR_PAGE_STATUS_CODE',

	/**
	 * Enable / Disable adding a user targeting in the LD context
	 */
	PLTUI_8115_BE_ADD_USER_TO_LD = 'PLTUI_8115_BE_ADD_USER_TO_LD',
	/**
	 * Enables the logic behind CSRF token creation and verification for some endpoints in sp-renderer (oauth/session)
	 */
	PLT_UI_SP_RENDERER_ENABLE_CSRF_TOKEN = 'PLT_UI_SP_RENDERER_ENABLE_CSRF_TOKEN',

	PLTUI84441_DPRNONCE = 'PLTUI84441_DPRNONCE',
	/**
	 * Weather the old Ext-user app shell is available.
	 */
	PLT_UI_EXT_USER_APPSHELL = 'PLT_UI_EXT_USER_APPSHELL',

	/**
	 * Weather the old Ext-registration app shell is available.
	 */
	PLT_UI_EXT_REGISTRATION_APPSHELL = 'PLT_UI_EXT_REGISTRATION_APPSHELL',
	/**
	 * Show build info as comments in the html source
	 */
	PLTUI3596_SHOW_BUILD_INFO = 'PLTUI3596_SHOW_BUILD_INFO',
	/**
	 *
	 */
	PLTUI3596_USE_IMPORT_MAP_OVERRIDES = 'PLTUI3596_USE_IMPORT_MAP_OVERRIDES',

	/**
	 * Allow declaration and rendering of meta MFEs
	 */
	PLTUI_ALLOW_META_MFES = 'PLTUI_ALLOW_META_MFES',

	/**
	 * Disable middleware that sets standard security headers on responses
	 */
	PLTUI_DISABLE_SECURITY_HEADERS_MIDDLEWARE = 'PLTUI_DISABLE_SECURITY_HEADERS_MIDDLEWARE',

	/**
	 * Clear session flags
	 */
	PLTUI8594_PLTUI_CLEAR_SESSION_CACHE_KEY_WITHOUT_TTL_DRYRUN = 'PLTUI8594_PLTUI_CLEAR_SESSION_CACHE_KEY_WITHOUT_TTL_DRYRUN',
	PLTUI8594_PLTUI_CLEAR_SESSION_CACHE_KEY_WITHOUT_TTL_WETRUN = 'PLTUI8594_PLTUI_CLEAR_SESSION_CACHE_KEY_WITHOUT_TTL_WETRUN',

	/**
	 * MFE 404 page
	 */
	PLTUI8691_MFE_404_PAGE = 'PLTUI8691_MFE_404_PAGE',

	/**
	 * Use strict prefix matching for MFE routing
	 */
	PLTUI9727_MATCH_ROUTE_PREFIX = 'PLTUI9727_MATCH_ROUTE_PREFIX',

	/**
	 * Client-side only feature flags
	 */
	PLTUI6294_LOG_MISSING_FF = 'PLTUI6294_LOG_MISSING_FF',
	PLTUI6294_PUBLIC_CLIENT_FF = 'PLTUI6294_PUBLIC_CLIENT_FF',
	PLTUI6294_REMOVE_FALLBACK_FF = 'PLTUI6294_REMOVE_FALLBACK_FF',

	/** Escape template JSON data for embedding in HTML */
	PLTUI_ESCAPE_JSON_TEMPLATE_DATA = 'PLTUI_ESCAPE_JSON_TEMPLATE_DATA',

	/**
	 * Enables the SLPT-Client-IP header when looking for geocoding
	 */
	PLTUI9763_GEOCODING_ENABLE_SLPTCLIENTIP_HEADER = 'PLTUI9763_GEOCODING_ENABLE_SLPTCLIENTIP_HEADER',

	/**
	 * Enables whether using x-real-ip when looking for geocoding
	 */
	PLTUI9763_CLOUDFLARE_VANITY_DOMAIN_ENABLED = 'PLTUI9763_CLOUDFLARE_VANITY_DOMAIN_ENABLED',

	/**
	 *  The API domain used by MFEs for client side API traffic provided by sp-tenant.
	 */
	PLTUI9513_USE_SP_TENANT_API_BASE_URL = 'PLTUI9513_USE_SP_TENANT_API_BASE_URL'
}
